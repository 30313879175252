//For more information on scss here, go see comment on media app project 
@use '@angular/material' as mat;
@include mat.core();

/*gold circle palette */
$md-goldcirclepalette: (
    50 : #fcf8ed,
    100 : #f8edd1,
    200 : #f4e2b3,
    300 : #f0d694,
    400 : #eccd7d,
    500 : #e9c466,
    600 : #e6be5e,
    700 : #e3b653,
    800 : #dfaf49,
    900 : #d9a238,
    A100 : #ffffff,
    A200 : #fff9f0,
    A400 : #ffe7bd,
    A700 : #ffdea3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$abble-primary: mat.define-palette($md-goldcirclepalette, 500);
$abble-accent: mat.define-palette($md-goldcirclepalette, 300, 500, A700);
$abble-warn: mat.define-palette($md-goldcirclepalette, 700);

$abble-theme: mat.define-light-theme((
  color: (
    primary: $abble-primary,
    accent: $abble-accent,
    warn: $abble-warn,
  )
));

:root {
  --font-family: "Inter", sans-serif;
  --font-secondary: "Poppins", sans-serif;
  --background-container: white;
  --warning: #dc3545;
  --primary: #e9c466;
  --disable: #f0d694;
  --secondary: #495057;
  --lightgray: #9a9a9a;
  --text-regular: #000;
}

@include mat.all-component-themes($abble-theme);
@import "~bootstrap/dist/css/bootstrap.css"; // Bootstrap import

body {
  background-color: unset;
  overflow: hidden;
}

.container {
  max-width: 1000px;
  background-color: var(--background-container);
  display: block;
  margin: auto;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  &.transparent {
    background-color: transparent;
    box-shadow: initial;
  }
}

button.mat-mdc-button {
  color: white;
  font-family: var(--font-secondary);
  font-size: 14px;
  background: var(--primary);
  padding: 25px 20px;
  text-transform: uppercase;
  border-radius: 30px;
  font-weight: 700;
  max-width: 300px;
  margin: 0 auto;
  white-space: nowrap;
  &.secondary {
    background: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
  }
  &.warning {
    background: var(--warning);
    color: white;
  }
  &.black-btn {
    background: black;
    color: white;
  }
  &:disabled {
    background: var(--disable);
    color: var(--lightgray);
  }
}

.loader {
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

::ng-deep {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 30px;
    border: 2px solid var(--primary);
  }
}

label {
  font-weight: 500;
  color: var(--text-regular);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-secondary);
  text-align: center;
  color: var(--primary);
  text-transform: uppercase;
  font-weight: 900;
}

svg {
  color: var(--primary);
  font-size: 30px;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
}

a {
  text-decoration: none;
  font-size: 14px;
  color: var(--lightgray);
  font-weight: 400;
  &:hover {
    color: var(--primary);
    text-decoration: underline;
    text-underline-offset: 10px;
  }
}

// Utils
.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-justify-content {
  justify-content: center;
}

.flex-justify-content-between {
  justify-content: space-between;
}

.flex-justify-content-end {
  justify-content: flex-end;
}

.flex-align-item-center {
  align-items: center;
}

.flex-fill {
  flex: 1 1 auto;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

// Utils
.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.flex-50 {
  flex: 1 1 50%;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33%;
}

.w-20 {
  width: 20%;
}

/**---------------------------------MEDIA QUERIES----------------------------------------*/
@media screen and (max-width: 768px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 14px;
  }
  h4 {
    font-size: 16px;
  }
  button.mat-mdc-button {
    max-width: 100%;
    overflow: hidden;
    white-space: inherit;
  }
}

@media screen and (max-width: 430px) {
  section {
    margin-top: 7rem;
    margin-bottom: 10rem;
  }
  h1 {
    font-size: 16px;
  }
  h4 {
    margin-left: 1.5rem;
  }
  .container {
    max-width: 90%;
    padding: 1.5rem;
  }
}
